.description {
  position: relative;
  z-index: 2;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  &__title {
    margin-bottom: 10px;
    font-size: 20px;
    color: #fff;
    text-align: center;
  }
  &__text {
    margin-bottom: 15px;
    padding: 0 10px;
    font-family: sans-serif;
    font-size: 16px;
    color: #fff;
    &::first-letter {
      font-family: 'Pattaya';
      font-size: 30px;
      line-height: 16px;
      margin-right: 4px;
    }
    &-color-red {
      &::first-letter {
        color: #ff006e;
      }
    }
    &-color-yellow {
      &::first-letter {
        color: #ffed66;
      }
    }
    &-color-brown {
      &::first-letter {
        color: #d09259;
      }
    }
    &-color-green {
      &::first-letter {
        color: #16db65;
      }
    }
    &-color-blue {
      &::first-letter {
        color: #3a86ff;
      }
    }
    &:last-child {
      text-align: center;
      margin-bottom: 0;
    }
    &-icon {
      position: relative;
      top: 5px;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 4px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &_yes {
        background-image: url('../../../../assets/icons/yes.svg');
      }
      &_almost {
        background-image: url('../../../../assets/icons/almost.svg');
      }
      &_no {
        background-image: url('../../../../assets/icons/empty.svg');
      }
    }
  }
}
