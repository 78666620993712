.app {
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
}

@media (min-width: 475px) {
  .app {
    width: 475px;
    margin: 0 auto;
    overflow: hidden;
  }
}