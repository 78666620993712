.burger {
  position: absolute;
  top: 5vh;
  left: 5vh;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background-color: #c6c6c6;
  background: url('./menu.svg') center / contain no-repeat;
  transition: 0.8s transform ease-in-out;
  z-index: 2;
  &__open {
    background: url('./menu-open.svg') center / contain no-repeat;
    transform: rotate(180deg);
    z-index: 2;
  }
}