
@font-face {
  font-family: 'Rum Raisin';
  src: url('../assets/fonts/RumRaisin-Regular.woff2') format('woff2'),
      url('../assets/fonts/RumRaisin-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Thin.woff2') format('woff2'),
      url('../assets/fonts/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
      url('../assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Bold.woff2') format('woff2'),
      url('../assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pattaya';
  src: url('../assets/fonts/Pattaya-Regular.woff2') format('woff2'),
      url('../assets/fonts/Pattaya-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

