.modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  &__show {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__window {
    overflow: hidden;
    position: absolute;
    top: 5vh;
    left: 5vw;
    width: 90vw;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &_bg {
      &-auth {
        background: url('./bg-start-view3.jpg') top / cover no-repeat;
      }
      &-descr {
        background: url('./bg-game-view3.jpg') top / cover no-repeat;
      }
      &-author {
        background: url('./bg-game-view.jpg') top / cover no-repeat;
      }
    }
    &-close {
      position: relative;
      z-index: 2;
      display: flex;
      align-self: flex-end;
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      margin-top: 15px;
      margin-right: 15px;
      margin-bottom: 10px;
      border: none;
      background: url('./circle_close.svg') center / contain no-repeat;
    }
    &_show {
      height: 90vh;
      animation-name: modal-show;
      animation-duration: 0.7s;
      animation-timing-function: ease-in-out;
    }
    &_close {
      height: 0vh;
      animation-name: modal-hide;
      animation-duration: 0.7s;
      animation-timing-function: ease-in-out;
    }
  }
}

@keyframes modal-show {
  from {
    height: 0;
  }
  to {
    height: 90vh;
  }
}

@keyframes modal-hide {
  from {
    height: 90vh;
  }
  to {
    height: 0;
  }
}

@media (min-width: 475px) {
  .modal {
    &__window {
      position: static;
    }
  }
}

@media (min-height: 670px) {
  .modal {
    &__window {
      &_bg {
        &-auth {
          background: url('./bg-start-view3.jpg') top / 140% no-repeat;
        }
        &-descr {
          background: url('./bg-game-view3.jpg') top / 140% no-repeat;
        }
        &-author {
          background: url('./bg-game-view.jpg') top / 140% no-repeat;
        }
      }
    }
  }
}
