.author {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  height: 80vh;
  padding: 15px 10px 10px 10px;
  &__info {
    flex-shrink: 0;
    color: #fff;
    font-size: 16px;
    text-align: center;
  }
  &__brand {
    width: 150px;
    height: 75px;
    margin-bottom: 40px;
    background: url('./logolws.png') center / contain no-repeat;
  }
  &__contacts {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 5vh;
    &-item {
      display: flex;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
    }
    &-link {
      display: flex;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      font-size: 1px;
      color: transparent;
      &_site {
        background: url('./site.svg') center / contain no-repeat;
      }
      &_email {
        background: url('./email.svg') center / contain no-repeat;
      }
      &_telegram {
        background: url('./telegram.svg') center / contain no-repeat;
      }
      &_github {
        background: url('./github.svg') center / contain no-repeat;
      }
    }
  }
}