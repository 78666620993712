.main-window {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('./bg-start-view.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: -4;
  }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(256, 256, 256, 0.4);
    z-index: -3;
  }
}

