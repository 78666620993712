.title {
  position: relative;
  margin-bottom: 12vh;
  
  transition: 1s opacity ease-in-out;
  visibility: hidden;
  opacity: 0;
  &__txt {
    position: relative;
    z-index: 1;
    font-family: 'Rum Raisin';
    letter-spacing: 10px;
    font-size: 40px;
  }
  &__decor {
    position: absolute;
    bottom: -10px;
    right: -20px;
    display: flex;
    &-color {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      background-color: #16db65;
      border-radius: 36%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      &:last-child {
        margin-right: 0;
      }
      &-red {
        background-color: #ff006e;
      }
      &-yellow {
        background-color: #ffed66;
      }
      &-green {
        background-color: #16db65;
      }
      &-blue {
        background-color: #3a86ff;
      }
      &-brown {
        background-color: #d09259;
      }
    }
  }
  &__visible {
    visibility: visible;
    opacity: 1;
  }
}