@import '../../../../style/variables.scss';

.btn-start {
  position: absolute;
  bottom: 10vh;
  right: 10vw;
  display: block;
  padding: 28px 20px;
  color: #474747;
  font-size: 30px;
  background-color: $greyBg;
  border: none;
  border-radius: 40%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: 1s opacity ease-in-out, 0.5s box-shadow ease-in-out;
  visibility: hidden;
  opacity: 0;
  &_visibility {
    visibility: visible;
  }
  &_opacity {
    opacity: 1;
  }
  &_pressed {
    box-shadow: rgba(255, 255, 255, 0.8) 0px 22px 70px 4px;
  }
}

@media (min-width: 475px) {
  .btn-start {
    bottom: 80px;
    right: 80px;
  }
}
